/* :root {
  --primary-color: #174035;
  --secondary-color: #47ee40;
  --pale-color: #edffe8;
  --orange-color: #fa4616;
  --disabled-color: #646464;
} */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

@font-face {
font-family: '__filson_6d4e91';
src: url(/_next/static/media/7ecbba81ea545b92-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__filson_6d4e91';
src: url(/_next/static/media/3722e269abfd2f5b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__filson_6d4e91';
src: url(/_next/static/media/119d489efff565b0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__filson_6d4e91';
src: url(/_next/static/media/8b159b600b5d964a-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__filson_Fallback_6d4e91';src: local("Arial");ascent-override: 83.06%;descent-override: 23.19%;line-gap-override: 2.26%;size-adjust: 110.41%
}.__className_6d4e91 {font-family: '__filson_6d4e91', '__filson_Fallback_6d4e91'
}

