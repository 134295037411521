/* :root {
  --primary-color: #174035;
  --secondary-color: #47ee40;
  --pale-color: #edffe8;
  --orange-color: #fa4616;
  --disabled-color: #646464;
} */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
